import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Header from "../components/Header"
import NextPage from "../components/NextPage"
import Calendar from "../components/Calendar"
import Button from "../components/Button"
import Card from "../components/Card"
import AvalonImage from "../components/AvalonImage"
import { smallContainer } from "../components/container"
import { colors } from "../theme"

// Hero Section
const StyledCard = styled(Card)`
  margin: 50px auto;
`

const headline = css`
  font-size: 36px;
  position: relative;
  top: -25px;
  margin-bottom: 0;
`

const bodyText = css`
  font-size: 18px;
  line-height: 1.3;
  color: ${colors.roseDark};
  max-width: 600px;
  margin: 20px 0;
`

const centeredBodyText = css`
  font-size: 18px;
  line-height: 1.3;
  color: ${colors.roseDark};
  max-width: 600px;
  margin: 20px auto;
  text-align: center;

  a {
    color: ${colors.black};
  }
`

const calendarWrapper = css`
  margin: 70px 0;
`

const bookButtonWrapper = css`
  margin: 100px auto 80px auto;
  text-align: center;
`

const bookButton = css`
  font-size: 21px;
  text-align: center;

  &:after {
    background-color: ${colors.accent};
  }

  &:hover:after {
    background-color: ${colors.accent};
  }
`

const avalonImage = css`
  max-width: 740px;
  margin: -130px auto 100px auto;
`

const WhereToStay = () => (
  <Layout>
    <SEO title="Where to stay" />
    <Header />
    <div css={smallContainer}>
      <StyledCard>
        <h1 css={headline}>Enjoy Palm Springs</h1>
        <p css={bodyText}>
          Our dream is to relax poolside, indulge in a few cocktails, and kill
          time between meals through good conversation with you. To keep the
          party intimate, you can book your accomodations in our room block
          under our name.
        </p>
        <p css={bodyText}>
          Below you'll find Mikey &amp; Ari's itenerary for the trip. Keep in
          mind there is a 2-night minimum for our room block at Avalon, Sunday
          Oct 10th &mdash; Monday Oct 11th.
        </p>
        <div css={calendarWrapper}>
          <h2>October</h2>
          <Calendar />
        </div>
        <div css={bookButtonWrapper}>
          <Button
            css={bookButton}
            href="https://gc.synxis.com/rez.aspx?Hotel=1921&Chain=5154&arrive=10/10/2021&depart=10/13/2021&adult=1&child=0&group=111021KAJD"
            target="_blank"
          >
            Book Now at Avalon Hotel
          </Button>
          <p css={centeredBodyText}>
            Guests can also contact Michael Treptow for information or
            assistance with booking by calling{" "}
            <a href="tel:+17606569106">(760) 656‑9106</a> or emailing{" "}
            <a href="mailto:michael.treptow@avalonpalmsprings.com">
              michael.treptow@avalonpalmsprings.com
            </a>
            .
          </p>
        </div>{" "}
      </StyledCard>
    </div>
    <div css={avalonImage}>
      <AvalonImage />
    </div>
    <NextPage to="/what-to-do">What to Do</NextPage>
  </Layout>
)

export default WhereToStay
